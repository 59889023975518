import React,{useEffect, useState} from 'react'
import "../../styles/pages/Register/sendPhoneNum.scss"
import {Dialog, InputBase, TextField} from "@material-ui/core"
import {connect} from "react-redux";
import {addPost, addRetrieve, dataDeleter} from "../../redux/action/DataManagerAction";
import Autocomplete from '@material-ui/lab/Autocomplete';
import logo from '../../assets/topbar/logo.svg'
import {countries} from '../../utils'
import { withRouter } from 'react-router-dom';
import {savePhone} from "../../redux/action/UserActions";
import { useSnackbar } from 'notistack';

function SendPhoneNum(props) {
    const {words}= props.words[props.lang];
    const [countryCode, setCountryCode] = useState('IR')
    const [countryName, setCountryName] = useState('Iran, Islamic Republic of')
    const [countryCallingCode, setCountryCallingCode] = useState('+')
    const { enqueueSnackbar } = useSnackbar();
    const [phone, setPhone] = useState('')

    const handleClose = () => {
        props.history.push('/')
    };

    function countryToFlag(isoCode) {
        return typeof String.fromCodePoint !== 'undefined'
          ? isoCode
              .toUpperCase()
              .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
          : isoCode;
    }

    const handleSubmit=()=>{
        props.addPost   ('/oauth2/send-code', { countryCode: countryCallingCode, phone: phone }, null, 'SendCode');
    }

    useEffect(()=>{
        if (props.data.postRequest['SendCode']) {
            if (!props.data.postRequest['SendCode'].error && props.data.postRequest['SendCode'].data.status === 'OK') {
                props.savePhone({phone: phone, countryCallingCode: countryCallingCode})
                props.history.push('/confirmation')
            } else {
                enqueueSnackbar(words.phoneEr , {
                    variant: 'error',
                    autoHideDuration: 3000,
                });
            }
        }
        return function cleanUp() {
            props.dataDeleter('postRequest', 'SendCode')
        }
    },[props.data.postRequest['SendCode']])

    return (
        <Dialog
            maxWidth='xs'
            open={true}
            onClose={handleClose}
            aria-labelledby="send-phoneNum-dialog"
        >
            <div className='p-15 pt-20 flex-column-center maxwidth343 text-center m-auto'>
                <img className='logoImg' src={logo} />
                <span className='mt-20 maxwidth-240'>{words.description}</span>
                <Autocomplete
                    className='mt-25'
                    options={countries}
                    autoHighlight
                    getOptionLabel={(option) => option.label}
                    // popupIcon={<SvgIcon><path id="Path_1803" data-name="Path 1803" d="M8.6,0,0,8.6l2.562,2.562L8.6,5.124l6.039,6.039L17.2,8.6Z" transform="translate(12.223 1.061) rotate(90)" fill="#c7c7c7" stroke="#fafafa" stroke-width="1.5"/></SvgIcon>}
                    closeIcon={null}
                    onChange={(e, value) => {setCountryCallingCode('+'+value.phone);setCountryName(value.label)}}
                    renderOption={(option) => (
                        <React.Fragment>
                            <span className="mr-10">{countryToFlag(option.code)}</span>
                            {option.label} +{option.phone}
                        </React.Fragment>
                    )}
                    renderInput={(params) =>
                        <TextField {...params}
                            style={{backgroundColor:"#FAFAFA"}}
                            variant='outlined'
                            placeholder="select your country"
                            value={countryName}
                        />
                    }
                />
                <div className='d-flex mt-15'>
                    <InputBase
                        placeholder="+"
                        className='phone-code-input'
                        value={countryCallingCode}
                        onChange={(e)=> setCountryCallingCode(e.target.value)}
                    />
                    <InputBase
                        placeholder="Your phone number"
                        className='phoneNum-input'
                        value={phone}
                        onChange={(e)=>setPhone(e.target.value)}
                    />
                </div>
                <button onClick={() => handleSubmit()} className='next-btn mt-20 pointer'>{words.next}</button>
                <div className='mt-20 w-100'>
                    <h6 className='or-line'><span>{words.or}</span></h6>
                </div>
                <span onClick={() => props.history.push('/publisherProfile')} className='m-5 publisher-signup pointer'>{words.signupPublisher}</span>
            </div>
        </Dialog>
    )
}

const mapStateToProps= state=>({
    lang: state.setup.lang,
    data: state.dataManager,
})

export default withRouter(connect(mapStateToProps, {savePhone, addPost, dataDeleter})(SendPhoneNum));
