import axios from "axios";
import localStorageService from "./localStorageService";
import {BaseUrl} from "../utils/index";

class JwtAuthService {

  // You need to send http request with email and passsword to your server in this method
  // Your server will return user object & a Token
  // User should have role property
  // You can define roles in app/auth/authRoles.js
  loginWithEmailAndPassword = async (username, password) => {
    let formData = new FormData; let self =this;
    formData.append('userType', 3);
    formData.append('username', username);
    formData.append('password', password);
    await axios({
      method:'post',
      url:`${BaseUrl}/user/login/`,
      data: formData
    }).then(async function (result) {
      if(result.data.status === 'OK'){
        await axios({
          method: 'get',
          url: `${BaseUrl}/api/admin/roots/getAdminByUser`,
          headers: {['Authorization']: `Bearer ${result.data.result.access_token}`},
        }).then(async response =>{
          console.log(response);
          if(response.data.status === 'OK'){
            let role= 'ADMIN'
            if(response.data.result.accessLevel === 1){
              role = 'ADMIN'
            }else if(response.data.result.accessLevel === 2){
              role = 'SECOND'
            }else if(response.data.result.accessLevel === 3){
              role = 'THIRD'
            }
            else if(response.data.result.accessLevel === 5){
              role = 'NAZEM'
            }else{
              role = "FOURTH"
            }
            console.log(role);
            let user={
              userId: '1',
              role: role,
              displayName: result.data.result.name,
              username: result.data.result.phone,
              token: result.data.result.access_token,
              refresh_token: result.data.result.refresh_token,
              photoURL: (result.data.result.image)?result.data.result.image:"/assets/images/user.jpg",
              access_token: result.data.result.access_token
            };
            console.log(user.token)
            await self.setSession(user.token);
            await self.setUser(user);
            return user
          }
        }).catch(error =>{
          console.log(error)
        })
      }
    }).catch(function (error) {
      console.log(error)
    });
  };

  // This method is being used when user logged in & app is reloaded
  loginWithToken = async () => {
    let self = this
    let user = localStorageService.getItem("auth_user");
    self.setSession(user['access_token']);
    self.setUser(user);
  };

  logout = () => {
    this.setSession(null);
    this.removeUser();
  }

  // Set token to all http request header, so you don't need to attach everytime
  setSession =async token => {
    if (token) {
      console.log(token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      await localStorage.setItem("jwt_admin_token", token);
      await localStorage.setItem("bar_admin_token", "Bearer " + token);
    } else {
      localStorage.removeItem("jwt_admin_token");
      delete axios.defaults.headers.common["Authorization"];
    }
  };

  // Save user to localstorage
  setUser = (user) => {
    localStorageService.setItem("userAuth", user);
  };
  // Remove user from localstorage
  removeUser = () => {
    localStorage.removeItem("userAuth");
  }
}

export default new JwtAuthService();
