import { combineReducers } from "redux";
import BookReducer from "./BookReducer";
import SetupReducer from "./SetupReducer";
import DataManagerReducer from "./DataManagerReducer";
import UserReducer from "./UserReducer"

const RootReducer = combineReducers({
    book: BookReducer,
    setup: SetupReducer,
    dataManager: DataManagerReducer,
    user: UserReducer,
});

export default RootReducer;
