export const StartPageWords={
    fa:{
        words: {
            title: 'سلام'
        }
    },
    en:{
        words: {
            title: 'hello'
        }
    }
}
