const initialState = {
    test: 'yes'
}

const BookReducer= function (state= initialState, action) {
    switch (action.type) {
        default: {
            return {
                ...state
            }
        }
    }
}

export default BookReducer;
