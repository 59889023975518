import React from "react";
import "./styles/index.scss"
import {Provider} from "react-redux";
import {Store} from "./redux/store";
import { BrowserRouter as Router, Route } from 'react-router-dom'
import history from './history'
import {RootRoutes} from "./pages/RootRoutes";
import TopBar from "./components/TopBar";
import Auth from "./auth/Auth";
import AuthGuard from "./auth/AuthGuard";
import AppContext from "./appContext";
import {SnackbarProvider} from "notistack";

function App() {
    return (
        <AppContext.Provider value={{routes: RootRoutes()}}>
            <Provider store={Store}>
                <SnackbarProvider maxSnack={1}
                                  anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'center',
                                  }}
                >
                    <Auth>
                        <Router history={history}>
                            <AuthGuard>
                                <TopBar>
                                    {RootRoutes().map((route, idx)=>{
                                        const {exact, path, component}= route;
                                        return(
                                            <Route key={idx} exact={exact} path={path} >
                                                {component}
                                            </Route>
                                        )
                                    })}
                                </TopBar>
                            </AuthGuard>
                        </Router>
                    </Auth>
                </SnackbarProvider>
            </Provider>
        </AppContext.Provider>
    );
}

export default App;
