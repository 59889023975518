import React from "react";
import {words} from "../../utils/pages/RootWords";
import SendPhoneNum from "./sendPhoneNum"
import Confirmation from "./confirmation"
import CompleteProfile from "./completeProfile"
import PublisherProfile from "./publisherProfile"

export const RegisterRoutes= [
    {
        exact: true,
        path: '/register',
        component: <SendPhoneNum words={words.sendPhoneNum}/>,
    },{
        exact: true,
        path: '/confirmation',
        component: <Confirmation words={words.confirmation}/>,
    },{
        exact: true,
        path: '/completeProfile',
        component: <CompleteProfile words={words.completeProfile}/>,
    },{
        exact: true,
        path: '/publisherProfile',
        component: <PublisherProfile words={words.publisherProfile}/>,
    }
]
