import React from 'react'
import "../../styles/pages/Register/publisherProfile.scss"
import { ReactComponent as AddAvatarIcon} from '../../assets/completeProfile/add-user.svg';
import { ReactComponent as PlusIcon} from '../../assets/publisherProfile/plus.svg';
import {Dialog, InputBase, Fab} from "@material-ui/core"
import {connect} from "react-redux";
import {addRetrieve} from "../../redux/action/DataManagerAction";
import { withRouter } from 'react-router-dom';

function PublisherProfile(props) {
    const {words}= props.words[props.lang];

    React.useEffect(()=>{
        props.addRetrieve('/oauth2/check-userId', {userId: 'shyn'}, {'Content-Type': 'application/json'}, 'CheckUserId');
    },[])

    React.useEffect(()=>{
        console.log("DATA;;;",props.data)
    },[props.data])

    const handleClose = () => {
        props.history.push('/confirmation')
    };

    const handleAddCertif = (e) => {
        console.log("ABCD::::",e)
    }

    return (
        <Dialog
            style={{minWidth:'400px'}}
            maxWidth='xs'
            open={true}
            onClose={handleClose}
            aria-labelledby="complete-profile-dialog"
        >
            <div className='p-15 pt-20 flex-column-center maxwidth374 text-center m-auto'>
                <span className='fontSize-17 fw-500'>{words.title}</span>
                <label className='addAvatar-container' htmlFor="upload-photo">
                    <input
                        accept="image/png, image/jpeg"
                        style={{ display: "none" }}
                        id="upload-photo"
                        name="upload-photo"
                        type="file"
                    />
                    <Fab className='w-100 h-100 addAvatar' disableFocusRipple disableRipple component="span" aria-label="add">
                        <AddAvatarIcon/>
                    </Fab>
                </label>
                <span className='mt-10 fontSize-12'>{words.information}</span> 
                <div className='d-flex mt-15'>
                    <div className="labels-container">
                        <span className='fontSize-14 w-100 mt-15'>*Name</span> 
                        <span className='fontSize-14 w-100 mt-15'>*Username</span> 
                    </div>
                    <div className="flex-column-center">
                        <InputBase
                            placeholder="Name"
                            className='name-input'
                        />
                        <InputBase
                            placeholder="Username"
                            className='name-input'
                        />
                    </div>
                </div>
                <span className='mt-10 fontSize-12 text-left w-100'>{words.notice}</span>
                <div className='d-flex mt-15'>
                    <div className="second-labels-container">
                        <span className='fontSize-14 w-100 mt-15'>*Email</span> 
                        <span className='fontSize-14 w-100 mt-15'>*{words.country}</span> 
                    </div>
                    <div className="flex-column-center">
                        <InputBase
                            placeholder="Email"
                            className='name-input'
                        />
                        <InputBase
                            placeholder="Country"
                            className='name-input'
                        />
                    </div>
                </div>
                <span className='mt-20 fontSize-14 text-left w-100'>{words.uploadCertificate}</span>
                <label className='addCertif-container' htmlFor="upload-photo">
                    <input
                        style={{ display: "none" }}
                        id="upload-photo"
                        name="upload-photo"
                        type="file"
                        onChange={(e) => handleAddCertif(e)}
                    />
                    <Fab className='w-100 h-100 addCertif' variant='extended' disableFocusRipple disableRipple component="span" aria-label="add">
                        <PlusIcon/>
                    </Fab>
                </label>
                <button type="file" className='next-btn mt-20 pointer'>{words.complete}</button> 
            </div>
        </Dialog>
    )
}

const mapStateToProps= state=>({
    lang: state.setup.lang,
    data: state.dataManager,
})

export default withRouter(connect(mapStateToProps, {addRetrieve})(PublisherProfile));
