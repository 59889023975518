import {deleteData, postReq, retrieveReq} from "../action/DataManagerAction";

const initialState = {
    list: [],
    retrieve: [],
    postRequest: [],
    deleteRequest: []
}

const DataManagerReducer= function (state= initialState, action) {
    switch (action.type) {
        case (postReq):{
            return {
                ... state,
                postRequest:{
                    ...state.postRequest,
                    [action.moduleName]:{
                        data: action.data,
                        error: action.error
                    }
                }
            }
        }
        case (retrieveReq):{
            return {
                ...state,
                retrieve: {
                    ...state.retrieve,
                    [action.moduleName]:{
                        data: action.data,
                        error: action.error
                    }
                }
            }
        }
        case (deleteData):{
            return {
                ...state,
                [action.payload]:{
                    ...state[action.payload],
                    [action.name]: null
                }
            }
        }
        default :{
            return {
                ...state
            }
        }
    }
}

export default DataManagerReducer
