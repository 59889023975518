import React, {useEffect, useState} from 'react'
import "../../styles/pages/Register/completeProfile.scss"
import { ReactComponent as AddAvatarIcon} from '../../assets/completeProfile/add-user.svg';
import { ReactComponent as AcceptIcon} from '../../assets/completeProfile/suggestedId.svg';
import {Dialog, InputBase, Fab, svgIcon} from "@material-ui/core"
import {connect} from "react-redux";
import {addPost, addRetrieve, dataDeleter} from "../../redux/action/DataManagerAction";
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack';

function CompleteProfile(props) {
    const {words}= props.words[props.lang];
    const { enqueueSnackbar } = useSnackbar();
    const phone= props.user.phone;
    const countryCallingCode= props.user.countryCallingCode;
    const code= props.user.code;

    const [name, setName]=useState('');
    const [userName, setUserName]=useState('');
    const [suggests, setSuggests] = useState([]);
    const [file, setFile]= useState(null);

    const changeName = (e) => {
        let newText = e.target.value;
        setName(newText);
        let userText= newText.replace(/\s+/g, "")
        setUserName(userText);
        props.addRetrieve('/oauth2/suggestion', { name: userText }, null, 'SuggestId')
    }

    const changeUserName = (e) => {
        let newText = e.target.value;
        let userText= newText.replace(/\s+/g, "")
        setUserName(userText);
        props.addRetrieve('/oauth2/check-userId', { userId: userText }, null, 'SuggestId');
    }

    const handleClose = () => {
        props.history.push('/confirmation')
    };

    const saveData = () => {
        let data = new FormData;
        data.append('username', userName);
        data.append('activeCode', code);
        data.append('countryCode', countryCallingCode);
        data.append('phoneNumber', phone);
        data.append('name', name);
        if(file) {
            data.append('file', file);
        }
        data.append('language', 'en');
        props.addPost('/oauth2/register', data, null, 'registerUser');
    }

    useEffect(() => {
        if (props.data.postRequest['registerUser']) {
            if (!props.data.postRequest['registerUser'].error && props.data.postRequest['registerUser'].data.status === 'OK') {
                console.log('user:::',props.data.postRequest['registerUser'].data.result);
            } else if(props.data.postRequest['registerUser'].error) {
                enqueueSnackbar(props.data.postRequest['registerUser'].error , {
                    variant: 'error',
                    autoHideDuration: 3000,
                });
            }else {
                enqueueSnackbar('please make sure about information' , {
                    variant: 'error',
                    autoHideDuration: 3000,
                });
            }
        }
        return function cleanUp() {
            props.dataDeleter('postRequest', 'registerUser');
        }
    }, [props.data.postRequest['registerUser']])

    useEffect(() => {
        if (props.data.retrieve['SuggestId']) {
            if (!props.data.retrieve['SuggestId'].error) {
                console.log('no suggest');
                if (suggests.length > 0) setSuggests([]);
            } else {
                setSuggests(props.data.retrieve['SuggestId'].data.result);
                console.log('suggests is now filled', suggests);
            }
        }
    }, [props.data.retrieve['SuggestId']])

    return (
        <Dialog
            maxWidth='xs'
            open={true}
            onClose={handleClose}
            aria-labelledby="complete-profile-dialog"
        >
            <div className='p-15 pt-20 flex-column-center maxwidth374 text-center m-auto'>
                <span className='fontSize-17 fw-500'>{words.title}</span>
                <label className='addAvatar-container' htmlFor="upload-photo">
                    <input
                        accept="image/png, image/jpeg"
                        style={{ display: "none" }}
                        id="upload-photo"
                        name="upload-photo"
                        type="file"
                        onChange={(e)=> {
                            if (e.target.files && e.target.files[0]) {
                                setFile(URL.createObjectURL(e.target.files[0]));
                            }
                        }}
                    />
                    {file ? (
                        <img className='avatar-preview' src={file}/>
                    ):(
                        <Fab className='w-100 h-100 addAvatar' disableFocusRipple disableRipple component="span" aria-label="add">
                            <AddAvatarIcon/>
                        </Fab>
                    )}
                </label>
                <span className='mt-10 fontSize-12'>{file? 'Change ' + words.information: 'Set ' + words.information}</span>
                <div className='d-flex mt-15'>
                    <div className="labels-container">
                        <span className='fontSize-14 w-100 mt-15'>*Name</span>
                        <span className='fontSize-14 w-100 mt-15'>*Username</span>
                    </div>
                    <div className="flex-column-center">
                        <InputBase
                            placeholder="Name"
                            className='name-input'
                            value={name}
                            onChange={(e)=>changeName(e)}
                        />
                        <InputBase
                            placeholder="Username"
                            className='name-input'
                            value={userName}
                            onChange={(e)=>changeUserName(e)}
                            style={(suggests.length != 0 && userName.length != 0) ? {border: '1px solid #e66161'}: null}
                            endAdornment={(suggests.length == 0 && userName.length != 0)?<AcceptIcon className='mr-10'/>:null}
                        />
                    </div>
                </div>
                {(suggests.length != 0 && userName.length != 0) ? (
                    <span className='mt-10 fontSize-12 text-left w-100 color-error'>{`The username ${userName} is not available`}</span>
                ):(
                    <span className='mt-10 fontSize-12 text-left w-100'>{words.notice}</span>
                )}
                {suggests.length != 0 && 
                    <div className='suggest-bar'>
                    {suggests.map((suggest, key) => {
                        return(
                            <div key={key} className="suggest-choice">
                                <span className='fontSize-13'>{suggest}</span>
                                <AcceptIcon onClick={() => {setUserName(suggest); setSuggests([])}} className='mb-1'/>
                            </div>
                        )
                    })}
                    </div>
                }
                <button onClick={()=>saveData()} type="file" className='next-btn mt-20 pointer'>{words.complete}</button>
            </div>
        </Dialog>
    )
}

const mapStateToProps= state=>({
    lang: state.setup.lang,
    data: state.dataManager,
    user: state.user
})

export default withRouter(connect(mapStateToProps, {addRetrieve, addPost, dataDeleter})(CompleteProfile));
