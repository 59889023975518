import {SET_LANG} from "../action/SetupAction";

const initialState = {
    lang: 'en'
}

const SetupReducer= function (state= initialState, action) {
    switch (action.type) {
        case (SET_LANG):{
            return {
                ...state,
                lang: action.payload
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}

export default SetupReducer;
