import {StartPageWords} from "./StartPages";
import {sendPhoneNumWords} from "./Register/sendPhoneNum";
import {confirmationWords} from "./Register/confirmation";
import {completeProfileWords} from "./Register/completeProfile"
import {publisherProfileWords} from "./Register/publisherProfile"

export const words={
    startPage: StartPageWords,
    sendPhoneNum: sendPhoneNumWords,
    confirmation: confirmationWords,
    completeProfile: completeProfileWords,
    publisherProfile: publisherProfileWords
};
