import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {IconButton, Button, InputBase} from '@material-ui/core';
import '../styles/components/topBar.scss'
import logo from '../assets/topbar/logo.svg'
import searchIcon from "../assets/topbar/search.svg"
import menuIcon from "../assets/topbar/menu.svg"
import { withRouter } from "react-router-dom";

const useStyles = makeStyles({
    noHover: {
        '&:hover': {
          backgroundColor: "transparent",
        }
    },
});

function TopBar(props) {
    const classes = useStyles();
    return(
        <>
            <div className="topbarContainer">
                <div className="mt-3 topbarActionContainer">
                    <div className='d-flex'>
                        <img className='mr-20' src={logo}/>
                        <div className="searchbarContainer">
                            <IconButton disableFocusRipple className={classes.noHover} aria-label="search">
                                <img src={searchIcon} />
                            </IconButton>
                            <InputBase
                                placeholder="Search"
                                className='searchInput'
                            />
                            <IconButton disableFocusRipple className={classes.noHover} aria-label="menu">
                                <img src={menuIcon} />
                            </IconButton>
                        </div>
                    </div>
                    <div>
                        <Button onClick={() => props.history.push('/register')} disableFocusRipple className='signupBtn mr-10'>
                            Sign up
                        </Button>
                        <Button onClick={() => props.history.push('/register')} disableFocusRipple variant="outlined" className='signinBtn'>
                            Sign in
                        </Button>
                    </div>
                </div>
            </div>
            {props.children}
        </>
    )
}

export default withRouter(TopBar);
