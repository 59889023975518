import StartPage from "./index";
import React from "react";
import {words} from "../../utils/pages/RootWords";

export const StartPageRoutes= [
    {
        exact: true,
        path: '/',
        component: <StartPage words={words.startPage}/>
    }
]
