export const sendPhoneNumWords={
    fa:{
        words: {
            description: 'لطفا کد کشور خود را انتخاب و شماره همراه خود را وارد کنید',
            next: 'تایید',
            or: 'یا',
            phoneEr: 'شماره معتبر نمیباشد. لطفا دوباره تلاش کنید.',
            signupPublisher: 'عضویت به عنوان ناشر',
        }
    },
    en:{
        words: {
            description: 'please confirm your country code and enter your phone number',
            next: 'Next',
            or: 'OR',
            phoneEr: 'Invalid phone number. please try again',
            signupPublisher: 'Sign up publisher account',
        }
    }
}
