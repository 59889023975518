export const confirmationWords={
    fa:{
        words: {
            title: 'تایید کد',
            information: 'تایید',
            next: 'یا',
            reSend: 'عضویت به عنوان ناشر',
            codeErr: 'کد وارد شده معتبر نمیباشد. لطفا دوباره تلاش کنید.'
        }
    },
    en:{
        words: {
            title: 'Confirmation',
            information: 'We have sent you an SMS with the code ',
            next: 'Next',
            reSend: "Haven't received the code?",
            codeErr: 'You have entered an invalid code. Please try again.'
        }
    }
}
