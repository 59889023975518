import React,{useState, useEffect} from 'react'
import "../../styles/pages/Register/confirmation.scss"
import {Dialog, InputBase} from "@material-ui/core"
import {connect} from "react-redux";
import {addPost, addRetrieve, dataDeleter} from "../../redux/action/DataManagerAction";
import { withRouter } from 'react-router-dom';
import {savePhone} from "../../redux/action/UserActions";
import { useSnackbar } from 'notistack';

function Confirmation(props) {
    const {words}= props.words[props.lang];
    const { enqueueSnackbar } = useSnackbar();
    const [code, setCode]=useState('');
    const [counter, setCounter] = useState(60);
    const phone= props.user.phone;
    const countryCallingCode= props.user.countryCallingCode;

    const handleClose = () => {
        props.history.push('/register')
    };

    const handleSubmit= ()=>{
        let data= {
            countryCode: countryCallingCode,
            phone: phone,
            activeCode: code
        }
        props.addPost('/oauth2/check-code', data, null, 'CheckCode_Login');
    }

    useEffect( ()=>{
        if(props.data.postRequest['CheckCode_Login']) {
            if (!props.data.postRequest['CheckCode_Login'].error && props.data.postRequest['CheckCode_Login'].data.status === 'OK') {
                if (props.data.postRequest['CheckCode_Login'].data.result) {
                    console.log('login:', props.data.postRequest['CheckCode_Login'].data.result);
                    props.history.push('/');
                }else{
                    props.savePhone({phone: phone, countryCallingCode: countryCallingCode, code: code})
                    props.history.push('/completeProfile');
                }
            }else {
                enqueueSnackbar(words.codeErr , {
                    variant: 'error',
                    autoHideDuration: 3000,
                });
            }
        }
        return function cleanUp () {
            props.dataDeleter('postRequest','CheckCode_Login')
        }
    },[props.data.postRequest['CheckCode_Login']])

    useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }, [counter]);

    const handleResend=()=>{
        if(counter===0){
            props.addPost('/oauth2/send-code', {
                countryCode: countryCallingCode,
                phone: phone
            }, null, 'resendCode')
            setCounter(60);
        }
    }

    return (
        <Dialog
            maxWidth='xs'
            open={true}
            onClose={handleClose}
            aria-labelledby="confirmation-dialog"
        >
            <div className='p-15 pt-20 flex-column-center maxwidth374 text-center m-auto'>
                <span className='fontSize-17 fw-500'>{words.title}</span>
                <span className='mt-20 fontSize-19'>{(countryCallingCode)?countryCallingCode:'+98'} {(phone)?phone:'9125169117'}</span>
                <span className='fontSize-14'>{words.information}</span>
                <InputBase
                    placeholder="Code"
                    className='confirmation-code-input'
                    value={code}
                    onChange={(e)=>setCode(e.target.value)}
                />
                <span onClick={()=>handleResend()} className='mt-15 re-send pointer'>{(counter!==0)?counter.toString():null} - {words.reSend}</span>
                <button onClick={() => handleSubmit()} className='next-btn mt-20 pointer'>{words.next}</button>
            </div>
        </Dialog>
    )
}

const mapStateToProps= state=>({
    lang: state.setup.lang,
    data: state.dataManager,
    user: state.user
})

export default withRouter(connect(mapStateToProps, {addPost, dataDeleter, savePhone})(Confirmation));
