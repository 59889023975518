export const completeProfileWords={
    fa:{
        words: {
            title: 'تایید کد',
            information: 'تایید',
            complete: 'یا',
            notice: 'سیزنی روش عوض میشه'
        }
    },
    en:{
        words: {
            title: 'Complete your profile',
            information: 'Profile picture',
            complete: 'Complete',
            notice: 'You can change your username by tapping on it.',
            error: 'The username is not available.'
        }
    }
}