import React from 'react'
import {connect} from "react-redux";
import {addRetrieve} from "../../redux/action/DataManagerAction";

function StartPage(props) {
    const {words}= props.words[props.lang];

    React.useEffect(()=>{
        props.addRetrieve('/oauth2/check-userId', {userId: 'shyn'}, {'Content-Type': 'application/json'}, 'CheckUserId');
    },[])

    React.useEffect(()=>{
        console.log(props.data)
    },[props.data])

    return(
        <div>
            {words.title}
        </div>
    )
}

const mapStateToProps= state=>({
    lang: state.setup.lang,
    data: state.dataManager,
})

export default connect(mapStateToProps, {addRetrieve})(StartPage);
