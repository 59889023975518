export const publisherProfileWords={
    fa:{
        words: {
            title: 'تایید کد',
            information: 'تایید',
            complete: 'یا',
            notice: 'سیزنی روش عوض میشه'
        }
    },
    en:{
        words: {
            title: 'Complete your profile',
            information: 'Set Profile picture',
            complete: 'Complete',
            notice: 'You can change your user name by tapping on it.',
            country: 'country',
            uploadCertificate: '*Upload Certifications'
        }
    }
}