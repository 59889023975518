import {BaseUrl} from "../../utils";
import axios from 'axios'

export const postReq= 'POST_REQUEST_ITEM_FOR_ACTION'
export const retrieveReq= 'RETRIEVE_REQUEST_GET_REQUEST_NO_LIST'
export const deleteData= 'DELETE_DATA_FORM_DATA_MANAGER_EVERY_WHERE'

export const dataDeleter=(group, name)=>dispatch=> {
    dispatch({
        type: deleteData,
        payload: group,
        name: name
    })
}


export function addPost(url, Data, params, moduleName, header=null) {
    console.log(Data);
    return dispatch =>{
        axios({
            method: 'post',
            url: BaseUrl+ url,
            headers: header,
            data: Data,
            params: params
        }).then(function (result) {
            console.log(result)
            if(result.data.status === 'OK') {
                return  dispatch({
                    type: postReq,
                    data: result.data,
                    moduleName,
                })
            }else{
                return dispatch({
                    type: postReq,
                    data: result.data,
                    error: 'Check',
                    moduleName
                })
            }
        }).catch(function (error) {
            console.log(error)
            return dispatch({
                type: postReq,
                data: error,
                error: (error.response)?error.response.data.message:error.message,
                moduleName
            })
        });
    }
}

export function addRetrieve(url, params, header, moduleName) {
    return dispatch=> {axios({
        method: 'get',
        url: BaseUrl + url,
        headers: header,
        params: params
    }).then(function (result) {
        if (result.data.status === 'OK') {
            return dispatch({
                type: retrieveReq,
                data: result.data,
                moduleName,
            })
        } else {
            return dispatch({
                type: retrieveReq,
                data: result.data,
                error: 'Check',
                moduleName
            })
        }
    }).catch(function (error) {
        return dispatch({
            type: retrieveReq,
            data: error.response.data,
            error: (error.response.data.message)?error.response.data.message:error.Error,
            moduleName
        })
    })
    }
}

