import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { setUserData } from "../redux/action/UserActions";
import jwtAuthService from "../services/jwtAuthService";
import localStorageService from "../services/localStorageService";
import history from "../history";

class Auth extends Component {
  state = {
    run: false
  };

  constructor(props) {
    super(props);

    this.props.setUserData(localStorageService.getItem("userAuth"));
    let self = this;
    if(localStorageService.getItem("userAuth")) {
      this.checkJwtAuth().then(async function (result) {
        self.setState({run: true})
      })
    }else{
      this.state={
        run: true
      }
    }
  }

  checkJwtAuth = async () => {
    await jwtAuthService.loginWithToken()
    .then(user => {
      if(localStorageService.getItem("userAuth")) {
        console.log(localStorageService.getItem("userAuth"));
        this.props.setUserData(localStorageService.getItem("userAuth"));
      }else{
        history.push({
          pathname: `/session/signin`
        });
      }
    }).catch(err => {
      history.push({
        pathname: `/session/signin`
      });
    });
  };

  render() {
    const { children } = this.props;
    console.log("RUN:::",this.state.run)
    if (this.state.run) {
      return <Fragment>{children}</Fragment>;
    }else{
      return null
    }
  }
}

const mapStateToProps = state => ({
  setUserData: PropTypes.func.isRequired,
  login: state.login
});

export default connect(
  mapStateToProps,
  { setUserData }
)(Auth);
